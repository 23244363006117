<template>
  <div class="hero is-aqua is-fullheight-with-navbar">
    <div class="hero-body is-paddingless">
      <div class="columns">
        <div class="column">
          <sn-form
            :subtitle="$t('contact.listen')"
            :button="$t('contact.send-btn')"
            :textField="$t('contact.field-name')"
            :textFieldIcon="'account'"
            :emailField="$t('contact.field-mail')"
            :boxField="$t('contact.field-message')"
            :showSubtitle="true"
            :showBoxField="true"
            :showTitle="false"
            :showTopLine="false"
            :showTelField="false"
            :subtitleClass="['has-text-zarzamora', 'is-1', 'is-uppercase']"
            @button-click="postContact($event)"
          />
        </div>
        <div class="column is-two-thirds">
          <b-image src="/static/contact_us.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SnForm from '@/components/SnForm.vue'

export default {
  name: 'Contact',
  components: {
    SnForm,
  },
  methods: {
    postContact(data) {
      const url = 'https://web.adminshopnet.com/establecimiento/quejas/web'
      const bodyHeaders = {
        'Content-Type': 'application/json',
        Authorization: 'e16a33a9927f2aee63862548535a6cc2',
      }

      data.type = 'contact_us'

      const body = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: bodyHeaders,
      }

      fetch(url, body)
    },
  },
}
</script>
