<template>
  <div class="hero is-fullheight-with-navbar">
    <div class="hero-body is-paddingless">
      <div class="columns">
        <div class="column is-one-third">
          <b-image
            src="https://mx-shopnet.adminshopnet.com/images/base-1era-parte.webp"
          />
        </div>
        <div class="column is-two-third">
          <sn-form-loyalnet
            :subtitle="$t('loyalnet.listen')"
            :button="$t('loyalnet.send-btn')"
            :textCompanyField="$t('loyalnet.field-company')"
            :textNameField="$t('loyalnet.field-name')"
            :textLastNameField="$t('loyalnet.field-lastname')"
            :textFieldIcon="'account'"
            :emailField="$t('loyalnet.field-mail')"
            :telField="$t('loyalnet.field-cellphone')"
            :boxField="$t('loyalnet.field-message')"
            :showSubtitle="true"
            :showBoxField="true"
            :showTitle="false"
            :showTopLine="false"
            :showTelField="true"
            :showTextCompanyField="true"
            :showTextNameField="true"
            :showTextLastNameField="true"
            :subtitleClass="['has-text-zarzamora', 'is-1', 'is-uppercase']"
            @button-click="postContact($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SnFormLoyalnet from '@/components/SnFormLoyalnet.vue'

export default {
  name: 'Loyalnet',
  components: {
    SnFormLoyalnet,
  },
  methods: {
    postContact(data) {
      const url = `${process.env.VUE_APP_HOST}/establecimiento/loyalnet/web`
      const bodyHeaders = {
        'Content-Type': 'application/json',
        Authorization: 'e16a33a9927f2aee63862548535a6cc2',
      }

      data.type = 'contact_us'

      const body = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: bodyHeaders,
      }

      fetch(url, body)
    },
  },
}
</script>
