<template>
  <div class="hero has-border-radius is-white">
    <div class="hero-body">
      <div class="columns">
        <div class="column">
          <hr class="hr" />
          <p class="title has-text-zarzamora is-4" v-html="title"></p>
          <ul class="unorder-list" v-for="item in itemList" :key="item">
            <li class="list-item subtitle is-6 has-text-zarzamora-light">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="column">
          <slot name="form"></slot>
          <b-image v-if="showImage" :src="image" rounded />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SnSectionModal',
  props: {
    title: {
      type: String,
      default: 'Defualt Title',
    },
    itemList: {
      type: Array,
      default: function () {
        return ['Item 1', 'Item 2', 'Item 3']
      },
    },
    image: {
      type: String,
      default: '',
    },
    showImage: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style scoped>
.has-border-radius {
  border-radius: 48px;
}
.list-item {
  list-style-type: disc;
  margin: 1rem;
}
.hr {
  border: 1px solid #307fe2;
  border-radius: 5px;
  width: 65px;
}
.hero-body {
  padding: 1.25rem 3rem;
}
</style>
