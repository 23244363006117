<template>
  <div class="DropQuestion">
    <div
      v-for="section in sections"
      :key="section.title"
      class="padding-top"
      :id="section.id"
    >
      <div class="columns">
        <div class="column has-text-centered">
          <p
            class="subtitle is-4 has-text-azur has-text-weight-semibold"
            v-html="section.title"
          ></p>
        </div>
      </div>
      <div
        class="columns is-centered"
        v-for="question in section.questions"
        :key="question.title"
      >
        <div class="column is-three-fifths">
          <p
            class="subtitle is-4 has-text-zarzamora"
            @click="
              question.video
                ? emitClick(question.video)
                : toggleQuestion(question)
            "
          >
            <span
              class="is-clickable"
              :class="question.video ? 'bg-color-azur' : ''"
            >
              {{ question.title }}
            </span>
            <b-icon
              v-if="question.answer"
              class="is-clickable is-pulled-right"
              :icon="showIcon(question)"
            />
          </p>
          <b-message
            v-if="question.answer"
            v-model="question.isActive"
            type="is-azur"
          >
            <p
              class="subtitle has-text-zarzamora-light"
              v-html="question.answer"
            ></p>
            <p
              v-if="isGiftcardQuestion(question.title)"
              @click="emitClick(question.title)"
              class="subtitle is-clickable is-underline has-text-zarzamora-light"
            >
              Ver Tutorial
            </p>
          </b-message>
          <hr class="hr" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      GIFTCARDS: [
        'AMAZON',
        'NETFLIX',
        'SPOTIFY',
        'GOOGLE PLAY',
        'XBOX',
        'PLAY STATION',
        'NINTENDO',
        'CINEPOLIS KLIC',
        'CINEPOLIS (CINE)',
        'CINEPOLIS (COMBOS)',
      ],
    }
  },
  props: {
    sections: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  methods: {
    emitClick(name) {
      this.$emit('click:showTutorial', name)
    },
    toggleQuestion(question) {
      question.isActive = !question.isActive
    },
    isGiftcardQuestion(title) {
      return this.GIFTCARDS.includes(title.toUpperCase())
    },
    showIcon(question) {
      if (!question.isActive) {
        return 'plus'
      }
      return 'minus'
    },
  },
}
</script>
<style scoped>
.hr {
  border: 1px solid #307fe2;
  border-radius: 5px;
  width: auto;
}
.is-underline {
  text-decoration: underline;
}
.padding-top {
  padding-top: calc(72px + 1.5rem);
}
.bg-color-azur {
  background-color: #eef6ff;
}
</style>
