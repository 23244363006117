<template>
  <div class="Home">
    <!-- Start Banner Section -->
    <div class="sn-hero">
      <sn-hero
        :title="$t('home.hero.title')"
        :subtitle="$t('home.hero.subtitle')"
        :button="$t('home.hero.button')"
        firstStore="/static/google-play.svg"
        secondStore="/static/app-store.svg"
        thirdStore="/static/huawei-store.png"
        @button-click="showVideoModal"
      />
    </div>
    <b-modal width="300px" v-model="isVideoModalActive">
      <video id="video" @click="togglePause" autoplay>
        <source
          src="/static/update/Video Tutorial - Conoce Shopnet (3).mp4"
          type="video/mp4"
        />
        Your browser does not support HTML video.
      </video>
    </b-modal>
    <!-- End Banner Section -->

    <!-- Start First Section -->
    <sn-section
      :title="$t('home.features.feature-header-1')"
      :subtitle="$t('home.features.feature-p-1')"
      :button="$t('home.buttons.more-info')"
      @button-click="showImageModal"
      firstImage="/static/OB-Tutorial-2.svg"
      secondImage="/static/Celulares.png"
    />

    <b-modal v-model="isImageModalActive">
      <figure class="image is-flex is-justify-content-center">
        <img :class="isMobile ? 'image-mobile' : ''" :src="imageModal" />
      </figure>
    </b-modal>
    <!-- End First Section -->
    <!-- Start First Section -->
    <sn-section
      title="¿Quieres saber cómo ganar Puntos Extra
con tu mismo ticket?"
      subtitle="Gana Puntos Extra al comprar productos específicos
que te den cashback adicional a los que ganas con
tu ticket del supermercado.<br />
Consulta en la App los productos participantes con
sus características y los Puntos Extra a obtener con
cada uno.<br />
¡Súmale más rápido a tu ahorro!
"
      hasCustomImage="points-img"
      :hasButton="false"
      firstImage="/static/PuntosExtra-Provisional.svg"
      secondImage="/static/update/Sección-P-Extra.png"
    />


    <!-- Start Second Section -->
    <sn-section-reverse
      class="is-aqua"
      :title="$t('home.features.feature-header-2')"
      :subtitle="$t('home.features.feature-p-2')"
      :button="$t('home.buttons.discover')"
      @button-click="toAffiliates"
      @second-button-click="toGifcards"
      :hasTwoButtons="true"
      buttonText="Tarjetas de Regalo"
      firstImage="/static/OB-Tutorial-3.svg"
      secondImage="/static/2.png"
    />
    <!-- End Second Section -->

    <!-- Start APP Image Section -->
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-8">
          <b-image src="/static/update/Screenshots-Shopnet.png" />
        </div>
      </div>
    </div>
    <!-- End APP Image Section -->

    <!-- Start Third Section -->
    <sn-section-reverse
      :title="$t('home.features.feature-header-3')"
      :subtitle="$t('home.features.feature-p-3')"
      :button="$t('home.buttons.more-info')"
      @button-click="showAppModal"
      firstImage="/static/OB-Tutorial-1.svg"
      secondImage="/static/4.png"
    >
      <template #stores>
        <div class="columns">
          <div class="column is-11 is-flex has-flex-gap is-align-items-center">
            <a
              :class="isMobile ? 'play-store' : ''"
              href=" https://play.google.com/store/apps/details?id=mx.com.shopnet&hl=en_US&gl=US"
              target="_blank"
            >
              <b-image src="/static/google-play.svg" />
            </a>
            <a
              :class="isMobile ? 'app-store' : ''"
              href="https://apps.apple.com/us/app/shopnet/id1230521098"
              target="_blank"
            >
              <b-image src="/static/app-store.svg" />
            </a>
            <a
              href="https://appgallery.huawei.com/app/C104130877"
              target="_blank"
            >
              <b-image class="huawei" src="/static/huawei-store.png" />
            </a>
          </div>
        </div>
      </template>
    </sn-section-reverse>

    <b-modal v-model="isAppModalActive">
      <sn-section-modal
        :title="$t('home.modals.app-modal.title')"
        :itemList="$t('home.modals.app-modal.items')"
        image="/static/Celular.png"
      />
    </b-modal>
    <!-- End Third Section -->

    <!-- Start Video Player Section -->
    <sn-section
      class="is-aqua"
      :title="$t('home.features.feature-header-4')"
      :subtitle="$t('home.features.feature-p-4')"
      :button="$t('home.buttons.contact-us')"
      @button-click="showContactModal"
      firstImage="/static/OB-Tutorial-5.svg"
      icon="email"
    >
      <template #mediaColumn>
        <figure class="image is-16by9">
          <iframe
            class="has-ratio"
            src="https://www.youtube.com/embed/Bji6k6ypCfc"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </figure>
      </template>
    </sn-section>
    <b-modal v-model="isContactModalActive">
      <sn-section-modal
        :title="$t('home.modals.contact-modal.title-1')"
        :showImage="false"
        :itemList="$t('home.modals.contact-modal.content')"
      >
        <template #form>
          <sn-form
            :title="$t('home.modals.contact-modal.title-2')"
            :text-field="$t('home.modals.contact-modal.field-business')"
            :email-field="$t('home.modals.contact-modal.field-mail')"
            :tel-field="$t('home.modals.contact-modal.field-phone')"
            :button="$t('home.modals.contact-modal.send-btn')"
            @button-click="postAffiliates($event)"
          />
        </template>
      </sn-section-modal>
    </b-modal>
    <!-- End Media Section -->

    <!-- Start Press Room Section -->
    <sn-press-room />
    <!-- End Press Room Section -->
  </div>
</template>

<script>
import SnSectionReverse from '@/components/SnSectionReverse'
import SnPressRoom from '@/components/SnPressRoom'
import SnSection from '@/components/SnSection'
import SnHero from '@/components/SnHero'

import SnSectionModal from '@/components/SnSectionModal'
import SnForm from '@/components/SnForm'

export default {
  name: 'Home',
  components: {
    SnSectionReverse,
    SnPressRoom,
    SnSection,
    SnHero,

    SnSectionModal,
    SnForm,
  },
  data() {
    return {
      mycofjasdf: false,
      data: 'MyVar',
      isImageModalActive: false,
      isAppModalActive: false,
      isContactModalActive: false,
      isVideoModalActive: false,
      isPaused: false,
    }
  },
  methods: {
    togglePause() {
      const videoPlayer = document.getElementById('video')

      if (this.isPaused) {
        videoPlayer.play()
        this.isPaused = false
      } else {
        videoPlayer.pause()
        this.isPaused = true
      }
    },
    showVideoModal() {
      this.isVideoModalActive = !this.isVideoModalActive
    },
    showImageModal() {
      this.isImageModalActive = !this.isImageModalActive
    },
    showAppModal() {
      this.isAppModalActive = !this.isAppModalActive
    },
    showContactModal() {
      this.isContactModalActive = !this.isContactModalActive
    },
    toAffiliates() {
      this.$router.push({ name: 'Affiliates' })
    },
    toGifcards() {
      this.$router.push({ name: 'Giftcards' })
    },
    postAffiliates(data) {
      const url = 'https://web.adminshopnet.com/establecimiento/quejas/web'
      const bodyHeaders = {
        'Content-Type': 'application/json',
        Authorization: 'e16a33a9927f2aee63862548535a6cc2',
      }

      data.type = 'affiliates'

      const body = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: bodyHeaders,
      }
      fetch(url, body)
    },
  },
  computed: {
    imageModal() {
      if (this.isMobile) {
        return this.$i18n.locale === 'es'
          ? 'https://mx-shopnet.adminshopnet.com/images/Baner+movl+vertical.png'
          : 'https://mx-shopnet.adminshopnet.com/images/Banner+movil+vertical+EN.png'
      }
      return this.$i18n.locale === 'es'
        ? 'https://mx-shopnet.adminshopnet.com/images/Banner+Registra+tickets.png'
        : 'https://mx-shopnet.adminshopnet.com/images/Banner+Register+Tickets.png'
    },
  },
}
</script>
<style>
.points-img {
  max-width: 280px;
  padding-bottom: 10px;
}
</style>
<style scoped>
.sn-hero {
  background: url('/static/marquee-bg.jpg') center center;
  background-size: cover;
}
.hr {
  border: 1px solid #307fe2;
  border-radius: 5px;
  width: 65px;
}
.image-mobile {
  max-width: 75%;
  border-radius: 16px;
}
.play-store {
  max-width: 40%;
}
.app-store {
  max-width: 37%;
}
video {
  border-radius: 8px;
}
.huawei {
  max-width: 160px;
}
</style>
