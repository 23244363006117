<template>
    <div id="app">
      <main>
        <transition name="fade">
          <router-view />
        </transition>
      </main>
    </div>
</template>
<script>
export default {
name: 'LoyalnetTemplate',
}
</script>