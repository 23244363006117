<template>
  <div class="About">
    <sn-section secondImage="/static/shoptransparent1.png">
      <template #textColumn>
        <div class="container">
          <p
            class="title is-1 is-spaced has-text-centered has-text-weight-bold has-text-zarzamora"
          >
            {{ $t('about.section.title-1') }}
          </p>
          <hr class="hr" />
          <p class="subtitle has-text-zarzamora-light has-text-weight-regular">
            {{ $t('about.section.subtitle-1') }}
          </p>
          <p class="subtitle has-text-zarzamora-light has-text-weight-regular">
            {{ $t('about.section.subtitle-1-2') }}
          </p>
        </div>
      </template>
    </sn-section>
    <sn-section-reverse class="is-aqua" secondImage="/static/femsa.png">
      <template #textColumn>
        <div class="container">
          <hr class="hr-small" />
          <p class="subtitle has-text-zarzamora-light has-text-weight-regular">
            {{ $t('about.section.subtitle-2') }}
          </p>
        </div>
      </template>
    </sn-section-reverse>
    <sn-section secondImage="/static/Mapa-paginaweb.png">
      <template #textColumn>
        <div class="container">
          <hr class="hr-small" />
          <p class="subtitle has-text-zarzamora-light has-text-weight-regular">
            {{ $t('about.section.subtitle-3') }}
          </p>
        </div>
      </template>
    </sn-section>
  </div>
</template>
<script>
import SnSection from '@/components/SnSection'
import SnSectionReverse from '@/components/SnSectionReverse'
export default {
  name: 'About',
  components: {
    SnSection,
    SnSectionReverse,
  },
}
</script>
<style scoped>
.hr {
  border: 1px solid #307fe2;
  border-radius: 5px;
  width: auto;
}
.hr-small {
  border: 1px solid #307fe2;
  border-radius: 5px;
  width: 65px;
}
</style>
