<template>
  <ShopnetTemplate v-if="$route.meta.template == 'shopnet'" />
  <LoyalnetTemplate v-else-if="$route.meta.template == 'loyalnet'" />
</template>
<script>
import ShopnetTemplate from '@/templates/ShopnetTemplate'
import LoyalnetTemplate from '@/templates/LoyalnetTemplate'

export default {
  name: 'App',
  components: { ShopnetTemplate, LoyalnetTemplate },
}
</script>
