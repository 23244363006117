<template>
  <div class="section">
    <hr v-if="showTopLine" class="hr" />
    <p v-if="showTitle" class="title" :class="titleClass">
      {{ title }}
    </p>
    <p v-if="showSubtitle" class="subtitle" :class="subtitleClass">
      {{ subtitle }}
    </p>

    <b-field v-if="showTextCompanyField">
      <b-input
        v-model="company"
        class="has-box-shadow"
        type="text"
        size="is-medium"
        icon-pack="mdi"
        :placeholder="textCompanyField"
      ></b-input>
    </b-field>

    <b-field v-if="showTextNameField">
      <b-input
        v-model="name"
        class="has-box-shadow"
        type="text"
        size="is-medium"
        icon-pack="mdi"
        :placeholder="textNameField"
      ></b-input>
    </b-field>

    <b-field v-if="showTextLastNameField">
      <b-input
        v-model="lastname"
        class="has-box-shadow"
        type="text"
        size="is-medium"
        icon-pack="mdi"
        :placeholder="textLastNameField"
      ></b-input>
    </b-field>

    <b-field v-if="showEmailField">
      <b-input
        v-model="email"
        class="has-box-shadow"
        type="email"
        size="is-medium"
        icon-pack="mdi"
        :placeholder="emailField"
      ></b-input>
    </b-field>

    <b-field v-if="showTelField">
      <b-input
        v-model="tel"
        class="has-box-shadow"
        type="tel"
        size="is-medium"
        icon-pack="mdi"
        :placeholder="telField"
      ></b-input>
    </b-field>

    <b-field v-if="showBoxField">
      <b-input
        v-model="textbox"
        class="has-box-shadow"
        type="textarea"
        size="is-medium"
        icon-pack="mdi"
        :placeholder="boxField"
      ></b-input>
    </b-field>

    <b-field class="is-flex is-justify-content-center">
      <b-button
        @click="buttonClick"
        type="is-azur"
        icon-pack="mdi"
        icon-right="send"
      >
        {{ button }}
      </b-button>
    </b-field>
  </div>
</template>
<script>
export default {
  name: 'SnFormLoyalnet',
  data() {
    return {
      company: '',
      name: '',
      lastname: '',
      email: '',
      tel: '',
      textbox: '',
    }
  },
  computed: {
    formData() {
      return {
        company: this.company,
        name: this.name,
        lastname: this.lastname,
        email: this.email,
        tel: this.tel,
        text_box: this.textbox,
      }
    },
  },
  methods: {
    buttonClick() {
      this.$emit('button-click', this.formData)
      this.company = ''
      this.name = ''
      this.lastname = ''
      this.email = ''
      this.tel = ''
      this.textbox = ''
    },
  },
  props: {
    title: {
      type: String,
      default: 'Defualt Title',
    },
    subtitle: {
      type: String,
      default: 'Defualt Subtitle',
    },
    button: {
      type: String,
      default: 'Default Button',
    },
    textCompanyField: {
      type: String,
      default: 'Default Text Field',
    },
    textNameField: {
      type: String,
      default: 'Default Text Field',
    },
    textLastNameField: {
      type: String,
      default: 'Default Text Field',
    },
    textFieldIcon: {
      type: String,
      default: 'domain',
    },
    telField: {
      type: String,
      default: 'Default Tel Field',
    },
    emailField: {
      type: String,
      default: 'Default Email Field',
    },
    boxField: {
      type: String,
      default: 'Default Box Field',
    },
    titleClass: {
      type: Array,
      default: function () {
        return ['has-text-zarzamora', 'is-4']
      },
    },
    subtitleClass: {
      type: Array,
      default: function () {
        return ['has-text-zarzamora', 'is-4']
      },
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showSubtitle: {
      type: Boolean,
      default: false,
    },
    showTopLine: {
      type: Boolean,
      default: true,
    },
    showTextCompanyField: {
      type: Boolean,
      default: false,
    },
    showTextNameField: {
      type: Boolean,
      default: false,
    },
    showTextLastNameField: {
      type: Boolean,
      default: false,
    },
    showEmailField: {
      type: Boolean,
      default: true,
    },
    showTelField: {
      type: Boolean,
      default: true,
    },
    showBoxField: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style scoped>
.has-box-shadow {
  box-shadow: 0px 3px 6px #00000029;
}
.button {
  border-radius: 8px;
}
.input,
.control,
.textarea {
  border-radius: 8px;
}

.input::placeholder,
.textarea::placeholder {
  opacity: 1;
  font-size: 1.2rem;
  color: #9f9c9c;
  border-radius: 8px;
}
.hr {
  border: 1px solid #307fe2;
  border-radius: 5px;
  width: 65px;
}
</style>
