import Vue from 'vue'
import VueRouter from 'vue-router'
//Views
import Contact from '@/views/Contact'
import About from '@/views/About'
import Home from '@/views/Home'
import Faq from '@/views/Faq'
import Loyalnet from '@/views/Loyalnet'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: '/home',
    meta: { template: 'shopnet' },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { template: 'shopnet' },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { template: 'shopnet' },
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: { template: 'shopnet' },
  },
  {
    path: '/loyalnet',
    name: 'Loyalnet',
    component: Loyalnet,
    meta: { template: 'loyalnet' },
  },
  {
    path: '/faqs/delete-facebook-account',
    redirect: { name: 'Faq' },
  },
  {
    path: '/preregister',
    name: 'Preregister',
    component: () => import('@/views/Preregister'),
    meta: { template: 'shopnet' },
  },
  {
    path: '/aviso-privacidad',
    name: 'NoticeOfPrivacy',
    component: () => import('@/views/NoticeOfPrivacy'),
    meta: { template: 'shopnet' },
  },
  {
    path: '/Terminos-Condiciones-Usuarios.html',
    name: 'TermsAndConditions',
    meta: { template: 'shopnet' },
  },
  {
    path: '/terminos-condiciones-establecimientos',
    name: 'TermsAndConditionsEstablishments',
    component: () => import('@/views/TNCEstablishments'),
    meta: { template: 'shopnet' },
  },
  {
    path: '/affiliates',
    name: 'Affiliates',
    component: () => import('@/views/Affiliates'),
    meta: { template: 'shopnet' },
  },
  {
    path: '/giftcards',
    name: 'Giftcards',
    component: () => import('@/views/Giftcards'),
    meta: { template: 'shopnet' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //Scroll positon between routes
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
})

export default router
