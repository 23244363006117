<template>
  <div class="hero is-fullheight-with-navbar">
    <div class="hero-body is-block">
      <div class="columns">
        <div class="column is-5 is-offset-1">
          <div class="container has-text-centered">
            <figure class="image is-inline-block is-110x110">
              <img src="/static/Logo-circular.png" />
            </figure>
            <div class="section">
              <p
                class="title is-2 is-spaced has-text-white has-text-weight-bold"
              >
                {{ title }}
              </p>
              <p class="subtitle has-text-nube has-text-weight-medium">
                {{ subtitle }}
              </p>
            </div>
            <b-button
              class="has-text-weight-semibold"
              @click="buttonClick"
              icon-left="play"
              size="is-medium"
              type="is-dulcamara"
              rounded
            >
              {{ button }}
            </b-button>
          </div>
          <br />
          <div class="container is-flex is-justify-content-center">
            <a
              href="https://play.google.com/store/apps/details?id=mx.com.shopnet&hl=en_US&gl=US"
              target="_blank"
            >
              <b-image :src="firstStore" />
            </a>
            <a
              href="https://apps.apple.com/us/app/shopnet/id1230521098"
              target="_blank"
            >
              <b-image :src="secondStore" />
            </a>
            <a
              href="https://appgallery.huawei.com/app/C104130877"
              target="_blank"
            >
              <b-image class="huawei" :src="thirdStore" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SnHero',
  methods: {
    buttonClick() {
      this.$emit('button-click')
    },
  },
  props: {
    title: {
      type: String,
      default: 'Default Title',
    },
    subtitle: {
      type: String,
      default: 'Default Subtitle',
    },
    button: {
      type: String,
      default: 'Default Button',
    },
    firstStore: {
      type: String,
      default: '',
    },
    secondStore: {
      type: String,
      default: '',
    },
    thirdStore: {
      type: String,
      default: '',
    },
  },
}
</script>
<style scoped lang="scss">
.is-110x110 {
  width: 110px;
  height: 110px;
}
.container {
  max-width: 553px !important;
}
.container.is-flex {
  gap: 0.5rem;
}
.title {
  padding-left: 1rem;
  padding-right: 1rem;
}
.button {
  margin-top: -5%;
}
.button.is-medium {
  font-size: 1.15rem;
}
.section {
  background-color: rgba(4, 13, 56, 0.4);
  border: 1px solid #307fe2;
  border-radius: 23px;
  padding: 4rem 1rem;
  margin-top: -10%;
}
.huawei {
  max-width: 160px;
}
</style>
