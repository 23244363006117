<template>
  <b-navbar class="title is-6 is-azur-dark is-capitalized" fixed-top>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ name: 'Home' }">
        <img src="/static/shopnet-logo-horizontal.png" />
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item tag="router-link" :to="{ name: 'About' }">
        {{ $t('navbar.about') }}
      </b-navbar-item>
      <b-navbar-item
        tag="a"
        target="_blank"
        href="https://web.adminshopnet.com/establecimiento/login"
      >
        {{ $t('navbar.establishment-access') }}
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'Contact' }">
        {{ $t('navbar.contact-us') }}
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'Faq' }">
        {{ $t('navbar.faqs') }}
      </b-navbar-item>
      <b-navbar-item tag="a" href="/faq#VideoTutoriales">
        {{ $t('navbar.tutorial') }}
      </b-navbar-item>
      <b-navbar-item tag="div">
        <b-button
          class="is-uppercase"
          type="is-azur-light"
          @click="goToStore"
          rounded
        >
          {{ $t('navbar.download') }}
        </b-button>
      </b-navbar-item>
      <b-dropdown position="is-bottom-left">
        <template #trigger="{ active }">
          <b-button
            type="is-azur-light"
            :icon-right="active ? 'chevron-up' : 'chevron-down'"
            rounded
          >
            {{ lang }}
          </b-button>
        </template>
        <b-dropdown-item v-if="!isEnglish" @click="switchLang('en')">
          {{ '🇺🇸' }}
        </b-dropdown-item>
        <b-dropdown-item v-else @click="switchLang('es')">
          {{ '🇪🇸' }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-navbar>
</template>
<script>
export default {
  name: 'SnNavBar',
  computed: {
    isEnglish() {
      return this.$i18n.locale == 'en'
    },
    lang() {
      return this.$i18n.locale == 'en' ? '🇺🇸' : '🇪🇸'
    },
    device() {
      const userAgent = navigator.userAgent.toLowerCase()
      const isAndroid = userAgent.indexOf('android') > -1
      const isApple = userAgent.indexOf('ios') > -1
      const isHuawei = userAgent.indexOf('huawei') > -1

      if (isAndroid) {
        return 'https://play.google.com/store/apps/details?id=mx.com.shopnet&hl=en_US&gl=US'
      } else if (isApple) {
        return 'https://apps.apple.com/us/app/shopnet/id1230521098'
      } else if (isHuawei) {
        return 'https://appgallery.huawei.com/app/C104130877'
      } else {
        return 'https://play.google.com/store/apps/details?id=mx.com.shopnet&hl=en_US&gl=US'
      }
    },
  },
  methods: {
    switchLang(lang) {
      return (this.$i18n.locale = lang)
    },
    goToStore() {
      window.open(this.device)
    },
    goTo(id) {
      const hash = Math.random().toString(36).substring(2)
      this.$router.push({ path: '/faq', hash: id, query: { a: hash } })
    },
  },
}
</script>
<style scoped>
.navbar {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-shadow: 0px 2px 2px 0px rgba(44, 45, 101, 0.29);
}
.button {
  height: 2rem;
}
.button.is-azur-light {
  border-color: #47abff;
  background-color: #2567ba;
}
.dropdown {
  display: flex;
  padding-top: 1.2rem;
}
.dropdown-item,
.dropdown .dropdown-menu .has-link a {
  font-size: 1.1rem;
}
a.dropdown-item,
.dropdown .dropdown-menu .has-link a,
button.dropdown-item {
  padding-right: 1rem;
  text-align: center;
}
.navbar.is-azur-dark .navbar-brand > a.navbar-item:focus,
.navbar.is-azur-dark .navbar-brand .navbar-link:focus {
  background-color: transparent;
  color: #fff;
}
.navbar.is-azur-dark .navbar-end > a.navbar-item:focus,
.navbar.is-azur-dark .navbar-end .navbar-link:focus {
  background-color: transparent;
  color: #fff;
}
</style>
