<template>
  <div class="section">
    <div class="columns is-centered is-vcentered">
      <slot name="mediaColumn">
        <div class="column is-two-fifths">
          <b-image :src="secondImage" />
        </div>
      </slot>
      <div class="column is-one-third is-offset-1">
        <slot name="textColumn">
          <b-image v-if="hasFirstImage" class="is-180x180" :src="firstImage" />
          <slot name="hr" />
          <p class="title" :class="titleClass">{{ title }}</p>
          <p class="subtitle" :class="subtitleClass">{{ subtitle }}</p>
          <slot name="stores" />
          <b-button
            v-if="hasButton"
            :label="button"
            :class="buttonClass"
            icon-left="plus"
            @click="buttonClick"
            rounded
          />
          <b-button
            v-if="hasTwoButtons"
            :label="buttonText"
            :class="[buttonClass, computedButtonClassess]"
            icon-left="plus"
            @click="secondButtonClick"
            rounded
          />
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SnSectionOne',
  methods: {
    buttonClick() {
      this.$emit('button-click')
    },
    secondButtonClick() {
      this.$emit('second-button-click')
    },
  },
  computed: {
    computedButtonClassess() {
      return window.innerWidth < 1426 ? 'mt-3' : 'ml-3'
    },
  },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    hasTwoButtons: {
      type: Boolean,
      default: false,
    },
    hasFirstImage: {
      type: Boolean,
      default: true,
    },
    firstImage: {
      type: String,
      default: '',
    },
    secondImage: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Default Title',
    },
    titleClass: {
      type: Array,
      default: function () {
        return ['has-text-zarzamora', 'is-spaced']
      },
    },
    subtitle: {
      type: String,
      default: 'Default Subtitle',
    },
    subtitleClass: {
      type: Array,
      default: function () {
        return ['has-text-zarzamora-light', 'has-text-weight-normal']
      },
    },
    hasButton: {
      type: Boolean,
      default: true,
    },
    button: {
      type: String,
      default: 'Default Button',
    },
    buttonClass: {
      type: Array,
      default: function () {
        return ['is-dulcamara', 'has-text-weight-semibold', 'has-text-white']
      },
    },
  },
}
</script>
