<template>
  <div id="app">
    <sn-nav-bar />
    <main>
      <transition name="fade">
        <router-view />
      </transition>
    </main>
    <footer>
      <sn-footer />
    </footer>
  </div>
</template>
<script>
import SnNavBar from '@/components/SnNavBar'
import SnFooter from '@/components/SnFooter'
export default {
  name: 'ShopnetTemplate',
  components: { SnNavBar, SnFooter },
}
</script>