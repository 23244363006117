<template>
  <div class="Faq">
    <div class="section">
      <p class="title is-1 has-text-zarzamora has-text-centered">
        {{ $t('faq.title') }}
      </p>
      <div v-if="!this.isMobile" class="columns is-multiline">
        <div class="column">
          <a href="#About"><b-image :src="aboutImg" /></a>
        </div>
        <div class="column">
          <a href="#Tickets"><b-image :src="ticketsImg" /></a>
        </div>
        <div class="column">
          <a href="#Points"><b-image :src="pointsImg" /></a>
        </div>
        <div class="column">
          <a href="#Payments"><b-image :src="paymentsImg" /></a>
        </div>
        <div class="column">
          <a href="#VideoTutoriales"><b-image :src="TutorialImg" /></a>
        </div>
        <div class="column">
          <a href="#Support"><b-image :src="supportImg" /></a>
        </div>
        <div class="column">
          <a href="#ExtraPoints"><b-image :src="extraPointsImg" /></a>
        </div>
      </div>
      <sn-drop-question
        @click:showTutorial="showTutorial($event)"
        :sections="$t('faq.sections')"
      />
      <b-modal width="300px" v-model="showModal">
        <video @click="togglePause" autoplay>
          <source :src="src" type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </b-modal>
    </div>
  </div>
</template>
<script>
import SnDropQuestion from '@/components/SnDropQuestion'

export default {
  name: 'Faq',
  data() {
    return {
      showModal: false,
      isPaused: false,
      src: '',
      GIFTCARDS: [
        {
          name: 'AMAZON',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Amazon.mp4',
        },
        {
          name: 'NETFLIX',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Netflix.mp4',
        },
        {
          name: 'NINTENDO',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Nintendo.mp4',
        },
        {
          name: 'PLAY STATION',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/PlayStation.mp4',
        },
        {
          name: 'SPOTIFY',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Spotify.mp4',
        },
        {
          name: 'GOOGLE PLAY',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Google+Play.mp4',
        },
        {
          name: 'POINTS',
          video: 'Puntos Shopnet.mp4',
        },
        {
          name: 'CINEPOLIS KLIC',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Cin%C3%A9polis.mp4',
        },
        {
          name: 'CINEPOLIS (CINE)',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Cin%C3%A9polis.mp4',
        },
        {
          name: 'CINEPOLIS (COMBOS)',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Cin%C3%A9polis.mp4',
        },
        { name: 'XBOX', video: 'Xbox.mp4' },
        { name: 'TICKETS', video: 'Registra tickets.mp4' },
        {
          name: 'ESTABLECIMIENTOS',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Pagayusapuntos-establecimientos.mp4',
        },
        {
          name: 'GIFTCARDS',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Pagayusapuntos-tarjetasderegalo.mp4',
        },
        {
          name: 'PAGO',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Verificaci%C3%B3n+de+tarjetas.mp4',
        },
        {
          name: 'STARBUCKS',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Starbucks.mp4',
        },
        {
          name: 'ENVIAFLORES',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/EnviaFlores.mp4',
        },
        {
          name: 'IOS',
          video: 'Registra tickets ANDROID.mp4',
        },
        {
          name: 'ANDROID',
          video: 'IOS Registra tickets.mp4',
        },
        {
          name: 'EXTRAPOINTS',
          url: 'https://shopnetmxdevelopment.s3.us-west-1.amazonaws.com/sources/Cashback+Pasos.mp4',
        },
        {
          name: '100CASHBACK',
          url: 'https://mx-shopnet.adminshopnet.com/images/TutorialCashback.mp4',
        },
      ],
    }
  },
  components: { SnDropQuestion },
  computed: {
    aboutImg() {
      return this.$i18n.locale === 'es'
        ? '/static/faq1_es.webp'
        : '/static/faq1_en.webp'
    },
    ticketsImg() {
      return this.$i18n.locale === 'es'
        ? '/static/faq2_es.webp'
        : '/static/faq2_en.webp'
    },
    pointsImg() {
      return this.$i18n.locale === 'es'
        ? '/static/faq3_es.webp'
        : '/static/faq3_en.webp'
    },
    paymentsImg() {
      return this.$i18n.locale === 'es'
        ? '/static/faq4_es.webp'
        : '/static/faq4_en.webp'
    },
    TutorialImg() {
      return this.$i18n.locale === 'es'
        ? '/static/faq5_es.webp'
        : '/static/faq5_en.webp'
    },
    supportImg() {
      return this.$i18n.locale === 'es'
        ? '/static/faq6_es.webp'
        : '/static/faq6_en.webp'
    },
    extraPointsImg() {
      return this.$i18n.locale === 'es'
        ? '/static/faq7_es.webp'
        : '/static/faq7_en.webp'
    },
  },

  mounted: function () {
    setTimeout(() => this.scrollFix(this.$route.hash), 0)
  },
  methods: {
    togglePause() {
      const videoPlayer = document.getElementById('video')

      if (this.isPaused) {
        videoPlayer.play()
        this.isPaused = false
      } else {
        videoPlayer.pause()
        this.isPaused = true
      }
    },
    scrollFix: function (hashbang) {
      location.hash = hashbang
    },
    showModalFn() {
      this.showModal = true
    },
    getUrl(giftcard) {
      if (giftcard[0].url) return giftcard[0].url

      if (giftcard[0].name === 'TICKETS') {
        return `https://dil0r5jxxhquc.cloudfront.net/sources/GiftCards/${giftcard[0].video}`
      }
      return `static/Tutoriales/${giftcard[0].video}`
    },
    showTutorial(name) {
      const giftcard = this.GIFTCARDS.filter(
        (value) => value.name === name.toUpperCase()
      )
      if (!giftcard[0]) return
      this.src = this.getUrl(giftcard)
      this.showModalFn()
    },
  },
}
</script>
<style scoped>
video {
  border-radius: 8px;
}
</style>
<style>
.modal-content {
  overflow: hidden !important;
}
</style>
